import React from "react";
import Master from "./components/Master";

function App() {
  return (
    <div>
      <Master />
    </div>
  );
}

export default App;
